<template>
  <v-container fluid >
    <v-row>
      <v-col cols="12">
        <v-card >
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">Lista parolacce</h2>
            <v-spacer />
          </v-card-title>
          <v-card-text>
            <v-container fluid grid-list-md >
              <v-row>
                <v-col cols="12">Aggiungi parola</v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-text-field hide-details outlined dense v-model="lng" label="Lingua"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-text-field hide-details outlined dense v-model="val" label="Testo"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-btn small color="accent" :disabled="!lng || !val" @click="insertProfanity()" :loading="loading" >Aggiungi</v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">Filtra parole</v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-text-field hide-details outlined dense v-model="lngSearch" label="Filtra lingua" @input="applyFilter"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-text-field hide-details outlined dense v-model="valSearch" label="filtra testo" @input="applyFilter"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-btn color="accent" @click="removeSelected()" :disabled="!selection.length">Elimina selezionati</v-btn>
                </v-col>
              </v-row>
              <v-row>

                <v-col cols="12">
                  <v-data-table v-model="selection" :headers="headers" :items="items" :options.sync="pagination" :server-items-length="count"
                                :loading="tableLoading" :footer-props="footerProps" show-select item-key="_id">

                    <template v-slot:item.remove="{ item }">
                      <v-btn color="error" dark icon v-on:click="requestDelete(item._id)">
                        <v-icon dark>mdi-close</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import config from "../config";
import utils from "../utils";
export default {
  data () {
    return {
      refreshTimeout : -1,
      loading : false,
      lng : null,
      val : null,
      lngSearch : null,
      valSearch : null,
      tableLoading : false,
      count : 0,
      items : [],
      headers : [
        { text : 'Lingua', sortable: true, align: 'left', value : 'lng' },
        { text : 'Testo', sortable: true, align: 'left', value : 'val' },
        { text : 'Elimina', sortable: false, align: 'center', value : 'remove', width : 50 },
      ],
      footerProps : {
        "items-per-page-text" : "n° elementi per pagina",
        "items-per-page-options" : [40,80,120],
        "items-per-page" : 40
      },
      pagination: {},
      query : {},
      selection : []
    }
  },
  watch: {
    pagination: {
      handler () {
        sessionStorage.profanitiesPagination = JSON.stringify(this.pagination)
        clearTimeout(this.refreshTimeout);
        this.refreshTimeout = setTimeout(this.refresh, 250);
      },
      deep: true
    }
  },
  methods : {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'remove','insert']),

    applyFilter() {
      this.query = {};
      this.query.lng = this.lngSearch ? encodeURIComponent(this.lngSearch) : undefined;
      this.query.val = this.valSearch ? encodeURIComponent(this.valSearch) : undefined;
      this.pagination.page = 1
      sessionStorage.profanitiesQuery = JSON.stringify(this.query)
      clearTimeout(this.refreshTimeout);
      this.refreshTimeout = setTimeout(this.refresh, 250);
    },
    requestDelete( id ) {
      this.requestConfirm({ title : 'Richiesta conferma', message : 'Sei sicuro di cancellare questo record?', callback: ( accepted )=>{
          if(accepted) {
            this.remove({ collection : 'profanities', id }).then(()=>{
              this.refresh();
              this.sendSuccess({message:'Record eliminato!'});
            }).catch((err)=>{
              let message = err.message ? err.message : "c'è stato un errore, riprova più tardi";
              this.sendError({ message });
            })

          }
        }});
    },
    removeSelected() {
      console.log(this.selection)
      this.requestConfirm({ title : 'Richiesta conferma', message : 'Sei sicuro di cancellare questi record?', callback: async ( accepted )=>{
          if(accepted) {
            try {
              await Promise.all(this.selection.map(item => {
                return this.remove({ collection : 'profanities', id : item._id})
              }))
              this.refresh();
              this.sendSuccess({message:'Record eliminati!'})
            } catch (err) {
              let message = err.message ? err.message : "c'è stato un errore, riprova più tardi";
              this.sendError({ message });
            }
          }
        }});
    },
    async insertProfanity() {
      if(!this.lng || !this.val) return;
      let data = { lng : this.lng, val : this.val };
      this.loading = true;
      try {
        await this.insert({collection:"profanities", data});
        this.refresh();
      } catch (e) {
        this.sendError({message:"c'è stato un errore, riprova più tardi"});
      }
      this.loading = false;
    },

    refresh() {
      this.tableLoading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      let sort = null;
      if(sortBy.length) {
        sort = sortBy[0];
        if(sortDesc[0]) {
          sort = `-${sort}`;
        } else {
          sort = `+${sort}`;
        }
      }

      this.load({ collection : 'profanities', query:this.query, limit:itemsPerPage, page : page-1, sort}).then((reply)=>{
        this.tableLoading = false;
        if(reply.data) {
          this.count = reply.count;
          this.items = reply.data;
        }
      }).catch((err)=>{
        this.tableLoading = false;
        this.sendError({message:"c'è stato un errore, riprova più tardi"});
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    }
  },
  mounted() {
    if(sessionStorage.profanitiesQuery) {
      this.query = JSON.parse(sessionStorage.profanitiesQuery)
      this.lngSearch = this.query.lng ? this.query.lng : ''
      this.valSearch = this.query.val ? this.query.val : ''
    }
    if(sessionStorage.profanitiesPagination) {
      this.pagination = JSON.parse(sessionStorage.profanitiesPagination);
    }
    this.refresh();
  }
}
</script>

<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">Scheda Opera</h2>
            <v-spacer />
            <v-btn text color="accent" small @click="save()" :loading="loading">Salva</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="langForm" @submit.prevent="addLanguage()" lazy-validation>
                <v-row>
                  <v-col cols="12"><h2 class="subheading">Lingue: {{languages.join(", ")}}</h2></v-col>
                  <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                    <v-text-field outlined dense label="Aggiungi lingua" v-model="newLang" :rules="[rules.numchars(2),rules.required]"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                    <v-btn color="accent" type="submit">Aggiungi lingua</v-btn>
                  </v-col>
                </v-row>
              </v-form>
              <v-divider class="mb-6" />
              <v-form ref="form" @submit.prevent="save()">
                <v-row>
                  <v-col cols="12" md="8" lg="6">
                    <v-text-field outlined dense label="Url imgf" v-model="editingItem.imgf_url" :rules="[rules.required]"></v-text-field>
                  </v-col>
                  <v-col cols="12"><h2 class="subheading">Titolo*</h2></v-col>
                  <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="lang in languages" :key="`title_${lang}`">
                    <v-text-field outlined dense  :label="lang" v-model="editingItem.title[lang]" :rules="[rules.required]"></v-text-field>
                  </v-col>
                  <v-col cols="12"><h2 class="subheading">Sottotitolo</h2></v-col>
                  <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="lang in languages" :key="`subtitle_${lang}`">
                    <v-text-field outlined dense  :label="lang" v-model="editingItem.subtitle[lang]" ></v-text-field>
                  </v-col>
                  <v-col cols="12"><h2 class="subheading">Artista/i*</h2></v-col>
                  <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="lang in languages" :key="`art_${lang}`">
                    <v-text-field outlined dense  :label="lang" v-model="editingItem.artist_name[lang]" :rules="[rules.required]"></v-text-field>
                  </v-col>
                  <v-col cols="12"><h2 class="subheading">Descrizione*</h2></v-col>
                  <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="lang in languages" :key="`desc_${lang}`">
                    <v-textarea outlined dense  :label="lang" v-model="editingItem.desc[lang]" :rules="[rules.required]"></v-textarea>
                  </v-col>
                  <v-col cols="12"><h2 class="subheading">Datazione*</h2></v-col>
                  <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="lang in languages" :key="`date_${lang}`">
                    <v-text-field outlined dense  :label="lang" v-model="editingItem.date_display[lang]" :rules="[rules.required]"></v-text-field>
                  </v-col>
                  <v-col cols="12"><h2 class="subheading">Dimensioni*</h2></v-col>
                  <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="lang in languages" :key="`size_${lang}`">
                    <v-text-field outlined dense  :label="lang" v-model="editingItem.size_display[lang]" :rules="[rules.required]"></v-text-field>
                  </v-col>
                  <v-col cols="12"><h2 class="subheading">Collocazione</h2></v-col>
                  <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="lang in languages" :key="`room_${lang}`">
                    <v-text-field outlined dense  :label="lang" v-model="editingItem.room_name[lang]" ></v-text-field>
                  </v-col>
                  <v-col cols="12"><h2 class="subheading">Materiali / Tecniche*</h2></v-col>
                  <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="lang in languages" :key="`tec_${lang}`">
                    <v-text-field outlined dense  :label="lang" v-model="editingItem.technique[lang]" ></v-text-field>
                  </v-col>
                  <v-col cols="12"><h2 class="subheading">Credits</h2></v-col>
                  <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="lang in languages" :key="`cr_${lang}`">
                    <v-text-field outlined dense  :label="lang" v-model="editingItem.credits[lang]" ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>

            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text color="accent" small @click="save()" :loading="loading">Salva</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import rules from "@/utils/rules";
export default {
  data () {
    return {
      loading : false,
      newLang : '',
      languages : ['it', 'en'],
      rules,
      editingItem : {
        title : { it: '', en: ''},
        subtitle : { it: '', en: ''},
        desc : { it: '', en: ''},
        credits : { it: '', en: ''},
        size_display : { it: '', en: ''},
        date_display : { it: '', en: ''},
        room_name : { it: '', en: ''},
        artist_name : { it: '', en: ''},
        technique : { it: '', en: ''},
        imgf_url : '',
      },
    }
  },
  computed : {
  },
  methods : {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'rawGet', 'update', 'insert']),
    addLanguage() {
      if(!this.$refs.langForm.validate()) return;
      if(this.languages.includes(this.newLang)) return;
      this.languages.push(this.newLang);
    },
    fillLanguagesByDict( dict ) {
      for(let lng in dict) {
        if(!this.languages.includes(lng))
          this.languages.push(lng)
      }
    },
    loadData() {
      this.loading = true;
      let id = this.$route.params.id;
      this.rawGet({path : 'artwork'}).then((reply)=>{
        this.loading = false;
        if(reply.data) {
          if(!reply.data.title)
            reply.data.title = { it: '', en: ''};
          if(!reply.data.subtitle)
            reply.data.subtitle = { it: '', en: ''};
          if(!reply.data.desc)
            reply.data.desc = { it: '', en: ''};
          if(!reply.data.credits)
            reply.data.credits = { it: '', en: ''};
          if(!reply.data.size_display)
            reply.data.size_display = { it: '', en: ''};
          if(!reply.data.date_display)
            reply.data.date_display = { it: '', en: ''};
          if(!reply.data.room_name)
            reply.data.room_name = { it: '', en: ''};
          if(!reply.data.artist_name)
            reply.data.artist_name = { it: '', en: ''};
          if(!reply.data.technique)
            reply.data.technique = { it: '', en: ''};


          this.editingItem = reply.data;

          this.fillLanguagesByDict(this.editingItem.title)
          this.fillLanguagesByDict(this.editingItem.subtitle)
          this.fillLanguagesByDict(this.editingItem.desc)
          this.fillLanguagesByDict(this.editingItem.credits)
          this.fillLanguagesByDict(this.editingItem.size_display)
          this.fillLanguagesByDict(this.editingItem.date_display)
          this.fillLanguagesByDict(this.editingItem.room_name)
          this.fillLanguagesByDict(this.editingItem.artist_name)
          this.fillLanguagesByDict(this.editingItem.technique)
        }
      }).catch((err) => {
        this.loading = false;
        let message = err.message ? err.message :"c'è stato un errore, riprova più tardi";
        this.sendError({message});
        this.$router.go(-1);
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    },
    async save() {
      if(!this.$refs.form.validate()) return;
      this.loading = true;
      try {
        let reply = await this.insert({collection:'artwork', data : this.editingItem})
        this.editingItem = reply.data;
        this.loading = false;
        this.sendSuccess({message:"Scheda opera salvata"})
      } catch (e) {
        let message = e.message ? e.message : "c'è stato un errore, riprova più tardi";
        this.sendError({ message });
        this.loading = false;
        if(e.statusCode === 401) {
          await this.$router.replace("/");
          this.logout();
        }
      }

    }
  },
  mounted() {
    if(this.$route.params.id !== 'new') {
      this.loadData()
    }
  }
}
</script>

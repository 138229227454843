<template>
  <v-container fluid >
    <v-row>
      <v-col cols="12">
        <v-card >
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">Lista ratings</h2>
            <v-spacer />
          </v-card-title>
          <v-card-text>
            <v-container fluid grid-list-md >
              <v-row>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-select hide-details outlined dense v-model="valSearch" label="Voto" :items="voteOptions" clearable @input="applyFilter"></v-select>
                </v-col>
              </v-row>
              <v-row>

                <v-col cols="12">
                  <v-data-table :headers="headers" :items="items" :options.sync="pagination" :server-items-length="count"
                                :loading="tableLoading"  >
                    <template v-slot:item.date="{ item }">
                      {{ formatDateTime(item.date) }}
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import config from "../config";
import utils from "../utils";
export default {
  data () {
    return {
      refreshTimeout : -1,
      valSearch : null,
      tableLoading : false,
      count : 0,
      items : [],
      headers : [
        { text : 'User ID', sortable: true, align: 'left', value : 'user_id' },
        { text : 'Vote', sortable: true, align: 'left', value : 'value' },
        { text : 'Date', sortable: true, align: 'left', value : 'date' },
      ],
      pagination: {},
      query : {}
    }
  },
  watch: {
    pagination: {
      handler () {
        sessionStorage.votePagination = JSON.stringify(this.pagination)
        clearTimeout(this.refreshTimeout);
        this.refreshTimeout = setTimeout(this.refresh, 250);
      },
      deep: true
    }
  },
  methods : {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'remove']),
    formatDateTime : utils.formatDateTime,
    applyFilter() {
      this.query = {};
      this.query.val = this.valSearch ? this.valSearch : undefined;
      this.pagination.page = 1
      sessionStorage.voteQuery = JSON.stringify(this.query)
      clearTimeout(this.refreshTimeout);
      this.refreshTimeout = setTimeout(this.refresh, 250);
    },


    refresh() {
      this.tableLoading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      let sort = null;
      if(sortBy.length) {
        sort = sortBy[0];
        if(sortDesc[0]) {
          sort = `-${sort}`;
        } else {
          sort = `+${sort}`;
        }
      }

      this.load({ collection : 'votes', query:this.query, limit:itemsPerPage, page : page-1, sort}).then((reply)=>{
        this.tableLoading = false;
        if(reply.data) {
          this.count = reply.count;
          this.items = reply.data;
        }
      }).catch((err)=>{
        this.tableLoading = false;
        this.sendError({message:"c'è stato un errore, riprova più tardi"});
        if(config.debug) console.error("Errore al caricamento degli utenti: " + err);
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    }
  },
  mounted() {
    if(sessionStorage.voteQuery) {
      this.query = JSON.parse(sessionStorage.voteQuery)
      this.valSearch = this.query.val ? this.query.val : ''
    }
    if(sessionStorage.votePagination) {
      this.pagination = JSON.parse(sessionStorage.votePagination);
    }
    this.refresh();
  }
}
</script>

<template>
  <v-container fluid grid-list-md >
    <v-row row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">Annotazioni</h2>
          </v-card-title>
          <v-card-text>
            <v-container fluid grid-list-md >
              <v-row row wrap>
                <v-col cols="12" lg="8">
                  <v-container grid-list-md>
                    <v-row row wrap>
                      <v-col cols="12">
                        <xl-viewer ref="xlviewer" :server="imgf.xlServer" :annotations="annotations" v-model="imgf.name" force-https/>
                      </v-col>
                      <v-col cols="12" class="text-xs-center">
                        <v-btn icon text color="accent" @click="zoomIn()"><v-icon>mdi-magnify-plus-outline</v-icon></v-btn>
                        <v-btn icon text color="accent" @click="zoomOut()"><v-icon>mdi-magnify-minus-outline</v-icon></v-btn>
                        <v-btn icon text color="accent" @click="reset()"><v-icon>mdi-autorenew</v-icon></v-btn>
                        <v-btn icon text color="accent" @click="newAnnotation()"><v-icon>mdi-comment-plus</v-icon></v-btn>
                      </v-col>
                    </v-row>
                  </v-container>

                </v-col>
                <v-col cols="12" lg="4">
                  <h2 class="title mb-2">Lista annotazioni</h2>
                  <v-checkbox v-model="showingAll" :indeterminate="showingSomething && !showingAll" label="Vedi tutto"></v-checkbox>
                  <v-list subheader two-line >
                    <template v-for="(item, index) in annotations" >
                      <v-divider :key="index"/>
                      <v-list-item :key="item._id">
                        <v-list-item-action>
                          <v-checkbox v-model="item.show" @change="refreshView"></v-checkbox>
                        </v-list-item-action>

                        <v-list-item-content @click="item.show = !item.show">
                          <v-list-item-title><b>{{item.title.it}}</b></v-list-item-title>
                          <v-list-item-subtitle class="accent--text">{{item.content ? item.content.it : ''}}</v-list-item-subtitle>
                          <v-list-item-subtitle>x:{{item.x.toFixed(4)}}, y:{{item.y.toFixed(4)}}</v-list-item-subtitle>
                          <v-list-item-subtitle>min zoom:{{item.min_zoom}}, y:{{item.max_zoom}}</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                          <v-btn v-if="!item.editing" icon text color="accent" @click="edit(item)"><v-icon>mdi-pencil</v-icon></v-btn>
                          <v-btn v-if="item.editing" icon text color="success" @click="save(item)"><v-icon>mdi-content-save</v-icon></v-btn>
                          <v-btn icon text color="accent" @click="editContent(item)"><v-icon>mdi-tooltip-edit</v-icon></v-btn>

                          <v-btn v-if="!item.editing" icon text color="error" @click="removeReq(item._id)"><v-icon>mdi-close</v-icon></v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </template>

                  </v-list>
                </v-col>
              </v-row>

            </v-container>
          </v-card-text>
          <!--v-card-actions>
              <v-spacer/>
              <v-btn text color="accent" @click="save()">Salva</v-btn>
          </v-card-actions-->
        </v-card>
      </v-col>
    </v-row>


    <v-dialog v-model="newAnnDialog" persistent max-width="1200">
      <v-card>
        <v-card-title class="headline">Inserisci annotazione</v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-form ref="langForm" @submit.prevent="addLanguage()" lazy-validation>
              <v-row>
                <v-col cols="12"><h2 class="subheading">Lingue: {{languages.join(", ")}}</h2></v-col>
                <v-col cols="12" md="6" lg="4" >
                  <v-text-field outlined dense label="Aggiungi lingua" v-model="newLang" :rules="[rules.numchars(2),rules.required]"></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" >
                  <v-btn color="accent" type="submit">Aggiungi lingua</v-btn>
                </v-col>
              </v-row>
            </v-form>

            <v-divider class="mb-6" />

            <v-form ref="form" @submit.prevent="create()" lazy-validation>
              <v-row row>
                <v-col cols="12"><h2 class="subheading">Titolo</h2></v-col>
                <v-col cols="12" md="6" lg="4" v-for="lang in languages" :key="`atitle_${lang}`">
                  <v-text-field outlined dense  :label="lang" v-model="newAnn.title[lang]" :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="12"><h2 class="subheading">Contenuto</h2></v-col>
                <v-col cols="12" md="6" lg="4" v-for="lang in languages" :key="`acontent_${lang}`">
                  <v-textarea outlined dense  :label="lang" v-model="newAnn.content[lang]"></v-textarea>
                </v-col>
                <v-col cols="12"><h2 class="subheading">Zoom</h2></v-col>
                <v-col cols="12" md="6" lg="4">
                  <v-text-field outlined dense type="number" step="1" v-model="newAnn.min_zoom" label="Zoom minimo" min="0" max="100"></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                  <v-text-field outlined dense type="number" step="1" v-model="newAnn.max_zoom" label="Zoom massimo" min="0" max="100"></v-text-field>
                </v-col>
                <v-col cols="12"><h2 class="subheading">Image</h2></v-col>
                <v-col cols="12" md="6" lg="4" v-if="imageUrl">
                  <v-img :src="imageUrl" :aspect-ratio="1" contain class="grey lighten-3"></v-img>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                  <v-file-input label="Image file" v-model="file" outlined dense></v-file-input>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.native="newAnnDialog = false">Annulla</v-btn>
          <v-btn color="accent" text @click.native="create(); newAnnDialog = false">Inserisci</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import config from "../config";
import XlViewer from "../components/XLviewer";
import rules from "@/utils/rules";
export default {
  components: {XlViewer},
  data () {
    return {
      loading : false,
      newLang : '',
      languages : ['it', 'en'],
      rules,
      newAnnDialog : false,
      newAnn : {
        title : { it : '', en : '' },
        content : { it : '', en : '' },
        x : 0.5,
        y : 0.5,
        min_zoom : 0,
        max_zoom : 100,
        image : ''
      },
      file : null,
      imgf : {
        xlServer : 'https://art.centrica.it/7711/closer/5c0e7e4e9263464388dd9eb8/',
        name : '626ba753b6f58eaef5c55fe0.imgf'
      },
      artwork : null,
      annotations : []
    }
  },
  computed : {
    imageUrl() {
      if(this.file) return URL.createObjectURL(this.file);
      if(this.newAnn.image) return this.newAnn.image;
      return null
    },
    showingAll : {
      get() {
        for(let i = 0; i < this.annotations.length; i++) {
          if(!this.annotations[i].show) return false;
        }
        return true;
      },
      set(val) {
        for(let i = 0; i < this.annotations.length; i++) {
          this.annotations[i].show = val;
        }
        this.refreshView();
      }
    },
    showingSomething() {
      for(let i = 0; i < this.annotations.length; i++) {
        if(this.annotations[i].show) return true;
      }
      return false;
    },
  },
  methods : {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'remove','get', 'update', 'insert','upload']),
    addLanguage() {
      if(!this.$refs.langForm.validate()) return;
      if(this.languages.includes(this.newLang)) return;
      this.languages.push(this.newLang);
    },
    zoomIn() {
      this.$refs.xlviewer.zoomIn()
    },
    zoomOut() {
      this.$refs.xlviewer.zoomOut()
    },
    reset() {
      this.$refs.xlviewer.reset()
    },
    newAnnotation() {
      this.newAnnDialog = true;
    },
    async applyFilter() {
      await this.loadAnnotations()
    },
    async loadAnnotations() {
      this.loading = true;
      try {
        let reply = await this.load({ collection : 'annotations', query: {}, page:1, limit:0, sort: null });
        if(reply.data) {
          this.annotations = reply.data;
        }
      } catch (e) {
        console.log(e)
      }
    },

    async create() {
      if(!this.$refs.form.validate()) return;
      this.newAnn.min_zoom = parseInt(this.newAnn.min_zoom, 10);
      this.newAnn.max_zoom = parseInt(this.newAnn.max_zoom, 10);

      let save;
      if(this.newAnn._id) {
        save = this.update;
      } else {
        save = this.insert;
        this.newAnn.x = 0.5;
        this.newAnn.y = 0.5;
      }
      try {
        let reply = await save({collection:'annotations', data: this.newAnn});
        if(reply.status === 'success' && reply.data) {

          if(this.file) {
            let formData = new FormData()
            formData.append('file', this.file)
            reply = await this.upload({where:`annotations/${reply.data._id}/image`, formData})
          }
          this.newAnn = {
            title : { it : '', en : '' },
            content : { it : '', en : '' },
            x : 0.5,
            y : 0.5,
            min_zoom : 0,
            max_zoom : 100,
            image : ''
          };


          reply.data.editing = true;
          reply.data.show = true;
          this.file = null;
          await this.loadAnnotations();
          this.sendSuccess({message:'Annotazione salvata!'});

        } else {
          this.sendError({message:'errore al salvataggio'})
        }


      } catch (err) {
        if(config.debug) console.log(err);
        let message = err.message ? err.message : "there was an error while saving data";
        this.sendError({message});
        if(err.statusCode === 401) {
          this.logout();
        }
      }
    },
    edit(item) {
      item.editing = true;
      item.show = true;
      this.$forceUpdate();
      this.$refs.xlviewer.forceRedraw();
    },
    editContent(item) {
      this.newAnn = item;
      this.newAnnDialog = true;
      this.languages = ['it','en']
      for(let lng in this.newAnn.title) {
        if(!this.languages.includes(lng))
          this.languages.push(lng)
      }
      if(this.newAnn.content){
        for(let lng in this.newAnn.content) {
          if(!this.languages.includes(lng))
            this.languages.push(lng)
        }
      }
    },
    save(item) {
      item.editing = false;
      this.$forceUpdate();
      this.$refs.xlviewer.forceRedraw();
      this.update({collection:'annotations', data : item}).then((reply)=>{
        if(config.debug) console.log(reply);

        this.sendSuccess({message:'Annotazione salvata!'})
      }).catch((err)=>{
        if(config.debug) console.log(err);
        let message = err.message ? err.message : "there was an error while saving data";
        this.sendError({message});
        if(err.statusCode === 401) {
          this.logout();
        }
      });
    },
    removeReq(id) {
      this.requestConfirm({  title :'Delete request', message : 'Are you sure to delete this item permanently?', callback: ( accepted )=>{
          if(accepted) {
            this.remove({ collection : 'annotations', id }).then(()=>{
              this.sendSuccess({message:'Item deleted!'});
              this.loadAnnotations();
            }).catch((err)=>{
              let message = err.message ? err.message :  "there was an error while removing data";
              this.sendError({ message });
              if(config.debug) console.error("Errore di cancellazione dell'annotazione",err);
              if(err.statusCode === 401) {
                this.logout();
              }
            })
          }
        }});
    },
    refreshView(){
      setTimeout(()=>{
        this.$forceUpdate();
        this.$refs.xlviewer.forceRedraw();
      }, 250);
    },
  },
  async mounted() {
    await this.loadAnnotations()
  }
}
</script>

<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card >
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">Scheda Autore</h2>
            <v-spacer />
            <v-btn text color="accent" small @click="save()" :loading="loading">Salva</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="langForm" @submit.prevent="addLanguage()" lazy-validation>
                <v-row>
                  <v-col cols="12"><h2 class="subheading">Lingue: {{languages.join(", ")}}</h2></v-col>
                  <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                    <v-text-field outlined dense label="Aggiungi lingua" v-model="newLang" :rules="[rules.numchars(2),rules.required]"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                    <v-btn color="accent" type="submit">Aggiungi lingua</v-btn>
                  </v-col>
                </v-row>
              </v-form>
              <v-divider class="mb-6" />
              <v-form ref="form" @submit.prevent="save()">
                <v-row>
                  <v-col cols="12"><h2 class="subheading">Nome artista*</h2></v-col>
                  <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="lang in languages" :key="`name_${lang}`">
                    <v-text-field outlined dense  :label="lang" v-model="editingItem.name[lang]" :rules="[rules.required]"></v-text-field>
                  </v-col>
                  <v-col cols="12"><h2 class="subheading">Biografia*</h2></v-col>
                  <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="lang in languages" :key="`biography_${lang}`">
                    <v-textarea outlined dense  :label="lang" v-model="editingItem.biography[lang]" :rules="[rules.required]"></v-textarea>
                  </v-col>
                  <v-col cols="12"><h2 class="subheading">Data di nascita</h2></v-col>
                  <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="lang in languages" :key="`bd_${lang}`">
                    <v-text-field outlined dense  :label="lang" v-model="editingItem.born_date[lang]" ></v-text-field>
                  </v-col>
                  <v-col cols="12"><h2 class="subheading">Luogo di nascita</h2></v-col>
                  <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="lang in languages" :key="`bp_${lang}`">
                    <v-text-field outlined dense  :label="lang" v-model="editingItem.born_place[lang]" ></v-text-field>
                  </v-col>
                  <v-col cols="12"><h2 class="subheading">Data morte</h2></v-col>
                  <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="lang in languages" :key="`dd_${lang}`">
                    <v-text-field outlined dense  :label="lang" v-model="editingItem.death_date[lang]" ></v-text-field>
                  </v-col>
                  <v-col cols="12"><h2 class="subheading">Luogo di morte</h2></v-col>
                  <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="lang in languages" :key="`dp_${lang}`">
                    <v-text-field outlined dense  :label="lang" v-model="editingItem.death_place[lang]" ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>

            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text color="accent" small @click="save()" :loading="loading">Salva</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import rules from "@/utils/rules";
export default {
  data () {
    return {
      loading : false,
      newLang : '',
      languages : ['it', 'en'],
      rules,
      editingItem : {
        name : { it: '', en: ''},
        biography : { it: '', en: ''},
        born_place : { it: '', en: ''},
        born_date : { it: '', en: ''},
        death_place : { it: '', en: ''},
        death_date : { it: '', en: ''},
      },
    }
  },
  computed : {
  },
  methods : {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'rawGet', 'update', 'insert']),
    addLanguage() {
      if(!this.$refs.langForm.validate()) return;
      if(this.languages.includes(this.newLang)) return;
      this.languages.push(this.newLang);
    },
    fillLanguagesByDict( dict ) {
      for(let lng in dict) {
        if(!this.languages.includes(lng))
          this.languages.push(lng)
      }
    },
    loadData() {
      this.loading = true;
      this.rawGet({path : 'artist'}).then((reply)=>{
        this.loading = false;
        if(reply.data) {
          if(!reply.data.name)
            reply.data.name = { it: '', en: ''};
          if(!reply.data.biography)
            reply.data.biography = { it: '', en: ''};
          if(!reply.data.born_place)
            reply.data.born_place = { it: '', en: ''};
          if(!reply.data.born_date)
            reply.data.born_date = { it: '', en: ''};
          if(!reply.data.death_place)
            reply.data.death_place = { it: '', en: ''};
          if(!reply.data.death_date)
            reply.data.death_date = { it: '', en: ''};
          this.editingItem = reply.data;

          this.fillLanguagesByDict(this.editingItem.name)
          this.fillLanguagesByDict(this.editingItem.biography)
          this.fillLanguagesByDict(this.editingItem.born_place)
          this.fillLanguagesByDict(this.editingItem.born_date)
          this.fillLanguagesByDict(this.editingItem.death_place)
          this.fillLanguagesByDict(this.editingItem.death_date)
        }
      }).catch((err) => {
        this.loading = false;
        let message = err.message ? err.message :"c'è stato un errore, riprova più tardi";
        this.sendError({message});
        this.$router.go(-1);
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    },
    async save() {
      if(!this.$refs.form.validate()) return;
      this.loading = true;
      try {
        let reply = await this.insert({collection:'artist', data : this.editingItem})
        this.editingItem = reply.data;
        this.loading = false;
        this.sendSuccess({message:"Scheda opera salvata"})
      } catch (e) {
        let message = e.message ? e.message : "c'è stato un errore, riprova più tardi";
        this.sendError({ message });
        this.loading = false;
        if(e.statusCode === 401) {
          await this.$router.replace("/");
          this.logout();
        }
      }

    }
  },
  mounted() {
    if(this.$route.params.id !== 'new') {
      this.loadData()
    }
  }
}
</script>

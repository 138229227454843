<template>
  <v-container fluid >
    <v-row>
      <v-col cols="12">
        <v-card >
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">Lista utenti</h2>
            <v-spacer />
            <v-btn color="accent" text @click="$router.push('/users/new')">Nuovo</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid grid-list-md >
              <v-row>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-text-field hide-details outlined dense v-model="emailSearch" type="email" label="Email" @input="applyFilter"></v-text-field>
                </v-col>
              </v-row>
              <v-row>

                <v-col cols="12">
                  <v-data-table :headers="headers" :items="items" :options.sync="pagination" :server-items-length="count"
                                :loading="tableLoading"  >

                    <template v-slot:item.email="{ item }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <a @click="$router.push(`/users/${item._id}`)" v-bind="attrs" v-on="on" class="text-decoration-underline"><b>{{ item.email }}</b></a>
                        </template>
                        <span>ID: {{item._id}}</span>
                      </v-tooltip>
                    </template>

                    <template v-slot:item.edit="{ item }">
                      <v-btn color="accent" dark icon v-on:click="$router.push(`/users/${item._id}`)">
                        <v-icon dark>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:item.remove="{ item }">
                      <v-btn color="error" dark icon v-on:click="requestDelete(item._id)">
                        <v-icon dark>mdi-close</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import config from "../config";
import utils from "../utils";
export default {
  data () {
    return {
      refreshTimeout : -1,
      emailSearch : null,
      tableLoading : false,
      count : 0,
      items : [],
      headers : [
        { text : 'Email', sortable: true, align: 'left', value : 'email' },
        { text : 'Modifica', sortable: false, align: 'center', value : 'edit', width : 50 },
        { text : 'Elimina', sortable: false, align: 'center', value : 'remove', width : 50 },
      ],
      pagination: {},
      query : {}
    }
  },
  watch: {
    pagination: {
      handler () {
        sessionStorage.usersPagination = JSON.stringify(this.pagination)
        clearTimeout(this.refreshTimeout);
        this.refreshTimeout = setTimeout(this.refresh, 250);
      },
      deep: true
    }
  },
  methods : {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'remove']),

    applyFilter() {
      this.query = {};
      this.query.email = this.emailSearch ? this.emailSearch : undefined;
      this.pagination.page = 1
      sessionStorage.usersQuery = JSON.stringify(this.query)
      clearTimeout(this.refreshTimeout);
      this.refreshTimeout = setTimeout(this.refresh, 250);
    },
    requestDelete( id ) {
      this.requestConfirm({ title : 'Richiesta conferma', message : 'Sei sicuro di cancellare questo record?', callback: ( accepted )=>{
          if(accepted) {
            this.remove({ collection : 'users', id }).then(()=>{
              this.refresh();
              this.sendSuccess({message:'Record eliminato!'});
            }).catch((err)=>{
              let message = err.message ? err.message : "c'è stato un errore, riprova più tardi";
              this.sendError({ message });
            })

          }
        }});
    },

    refresh() {
      this.tableLoading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      let sort = null;
      if(sortBy.length) {
        sort = sortBy[0];
        if(sortDesc[0]) {
          sort = `-${sort}`;
        } else {
          sort = `+${sort}`;
        }
      }

      this.load({ collection : 'users', query:this.query, limit:itemsPerPage, page : page-1, sort}).then((reply)=>{
        this.tableLoading = false;
        if(reply.data) {
          this.count = reply.count;
          this.items = reply.data;
        }
      }).catch((err)=>{
        this.tableLoading = false;
        this.sendError({message:"c'è stato un errore, riprova più tardi"});
        if(config.debug) console.error("Errore al caricamento degli utenti: " + err);
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    }
  },
  mounted() {
    if(sessionStorage.usersQuery) {
      this.query = JSON.parse(sessionStorage.usersQuery)
      this.emailSearch = this.query.email ? this.query.email : ''
    }
    if(sessionStorage.usersPagination) {
      this.pagination = JSON.parse(sessionStorage.usersPagination);
    }
    this.refresh();
  }
}
</script>

<template>
  <v-container fluid grid-list-md >
    <v-row row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">{{title}}</h2>
            <v-spacer/>
            <v-btn text color="accent" @click="save()">Salva</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid grid-list-md >
              <v-row>

                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field label="Nome" outlined dense v-model="editingItem.name"
                                :error="nameError" :error-messages="requiredErrorMessages(nameError)"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="2">
                  <v-text-field label="Codice" outlined dense v-model="editingItem.code"
                                :error="codeError" :error-messages="requiredErrorMessages(codeError)"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text color="accent" @click="save()">Salva</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import {  mapActions } from 'vuex';
import config from "../config";
export default {
  data () {
    return {
      editingItem : {
        code : '',
        name : '',
      },
      measureError : false,
      measureRatioError : false,
      codeError : false,
      nameError : false,
    }
  },
  computed : {
    title() {
      return this.$route.params.id === 'new' ? 'Nuova lingua' : 'Modifica lingua';
    },
    loading : {
      get() {
        return this.$store.state.loading;
      },
      set(val) {
        this.$store.state.loading = val;
      }
    },

  },
  methods : {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load','get', 'update', 'insert']),
    requiredErrorMessages(err) {
      if(err) return ["campo richiesto"];
      return []
    },
    loadData() {
      this.loading = true;

      let id = this.$route.params.id;
      this.get({collection : 'languages', id}).then((reply)=>{
        this.loading = false;
        if(reply.data) {
          this.editingItem = reply.data;
        }
      }).catch((err) => {
        this.loading = false;
        let message = err.message ? err.message : "there was an error while loading data";
        this.sendError({message});
        this.$router.go(-1);
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    },
    clearErrors() {
      this.nameError = false;
      this.codeError = false;
    },
    save() {
      this.clearErrors();
      let updating = this.editingItem._id != null;

      if(!this.editingItem.code) {
        this.codeError = true;
        return;
      }
      if(!this.editingItem.name) {
        this.nameError = true;
        return;
      }

      let saveFn = updating ? this.update : this.insert;
      saveFn({collection:'languages', data : this.editingItem}).then((reply)=>{
        this.sendSuccess({ message : "Lingua salvata"});
        if(this.$route.params.id === 'new') {
          this.$router.replace("/languages/"+ reply.data._id);
        } else {
          this.editingItem = reply.data;
        }
      }).catch((err)=>{
        if(config.debug) console.log("Save language error:", err);
        let message = err.message ? err.message : "there was an error while saving data";
        this.sendError({ message });
        if(err.statusCode === 401) {
          this.$router.replace("/");
          this.logout();
        }
      })

    }
  },
  mounted() {
    if(this.$route.params.id !== 'new') {
      this.loadData()
    }
  }
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',{staticClass:"pb-0"},[_c('h2',{staticClass:"display-1 ml-4"},[_vm._v("Lista help")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"accent","text":""},on:{"click":function($event){return _vm.$router.push('/helps/new')}}},[_vm._v("Nuovo")])],1),_c('v-card-text',[_c('v-container',{attrs:{"fluid":"","grid-list-md":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-text-field',{attrs:{"hide-details":"","outlined":"","dense":"","label":"Titolo"},on:{"input":_vm.applyFilter},model:{value:(_vm.titleSearch),callback:function ($$v) {_vm.titleSearch=$$v},expression:"titleSearch"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.pagination,"server-items-length":_vm.count,"loading":_vm.tableLoading},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.title.it",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"text-decoration-underline",on:{"click":function($event){return _vm.$router.push(("/helps/" + (item._id)))}}},'a',attrs,false),on),[_c('b',[_vm._v(_vm._s(item.title.it))])])]}}],null,true)},[_c('span',[_vm._v("ID: "+_vm._s(item._id))])])]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"accent","dark":"","icon":""},on:{"click":function($event){return _vm.$router.push(("/helps/" + (item._id)))}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil")])],1)]}},{key:"item.remove",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"error","dark":"","icon":""},on:{"click":function($event){return _vm.requestDelete(item._id)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-close")])],1)]}}])})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from "@/config";

Vue.use(Vuex)

const heartbeat = (commit) => {

  const request = async () => {
    let { data } = await axios.get(`${config.server}/heartbeat`, { headers : { 'x-token' : `${sessionStorage.token}`} })
    commit('token', { value : data.data.token });
  }

  request().then(() => {
    setTimeout(()=>{heartbeat(commit)}, 300000)
  }).catch((err) => {
    commit('token', { value : null });
  })

}


export default new Vuex.Store({
  state: {
    token : sessionStorage.token,
    languages : [],
    profanities : [],
    helps : [],
    fp : [],
    fn : [],
    dialogMessage : '',
    successDialog : false,
    errorDialog : false,
    confirmDialog : false,
    confirmDialogTitle: 'Richiesta conferma',
    confirmDialogMessage : 'Sei sicuro?',
    confirmDialogCallback : null,
  },
  mutations: {
    token:(state, { value }) => {
      state.token = value;
      sessionStorage.token = value;
    },
    profanities:(state, { data }) => {
      state.profanities = data;
    },
    languages:(state, { data }) => {
      state.languages = data;
    },
    helps:(state, { data }) => {
      state.helps = data;
    },
    fp:(state, { data }) => {
      state.fp = data;
    },
    fn:(state, { data }) => {
      state.fn = data;
    },
    message : (state, { type, message }) => {
      switch (type) {
        case 'success':
          state.successDialog = true;
          state.dialogMessage = message;
          break;
        case 'error':
          state.errorDialog = true;
          state.dialogMessage = message;
          break;
      }
    },
    confirm : (state, { title, message, callback }) => {
      state.confirmDialogTitle = title || 'Richiesta conferma';
      state.confirmDialogMessage = message || 'Sei sicuro?';
      state.confirmDialogCallback = callback || null;
      state.confirmDialog = true;
    },
  },
  getters: {
    loggedIn: (state) => !!state.token,
    helps: (state) => state.helps,
    languages: (state) => state.languages,
  },
  actions: {
    sendSuccess({ commit }, { message }) {
      commit('message', { type : 'success', message })
    },
    sendError({ commit }, { message }) {
      commit('message', { type : 'error', message })
    },
    requestConfirm({commit}, { title, message, callback }) {
      commit('confirm', { title, message, callback })
    },
    logout({commit}) {
      commit('token', { value : null });
    },
    async login({commit}, {email,password}){
      let { data } = await axios.post(`${config.server}/login`, {email,password})
      commit('token', { value : data.data.token });
    },
    heartbeat({commit}){
      heartbeat(commit);
    },
    async loadAll({commit}, {collection}) {
      let { data } = await axios.get(`${config.server}/api/${collection}?limit=0&page=1`, { headers : { 'x-token' : `${sessionStorage.token}`} })
      commit(collection, { data : data.data })
    },
    async load({commit}, {collection, query, page, limit, sort}) {
      let params = {
        ...query,
        page,
        limit,
        sort
      }
      let { data } = await axios.get(`${config.server}/api/${collection}`, { headers : { 'x-token' : `${sessionStorage.token}`}, params })
      return data;
    },
    async get({commit}, {collection, id}) {
      let { data } = await axios.get(`${config.server}/api/${collection}/${id}`, { headers : { 'x-token' : `${sessionStorage.token}`} })
      return data;
    },
    async rawGet({commit}, { path }) {
      let { data } = await axios.get(`${config.server}/api/${path}`, { headers : { 'x-token' : `${sessionStorage.token}`} })
      return data;
    },
    async insert({commit}, {collection, data }) {
      let res = await axios.post(`${config.server}/api/${collection}`, data, { headers : { 'x-token' : `${sessionStorage.token}`} })
      return res.data;
    },
    async update({commit}, {collection, data }) {
      let res = await axios.put(`${config.server}/api/${collection}/${data._id}`, data, { headers : { 'x-token' : `${sessionStorage.token}`} })
      return res.data;
    },
    async remove({commit}, {collection, id}) {
      let { data } = await axios.delete(`${config.server}/api/${collection}/${id}`, { headers : { 'x-token' : `${sessionStorage.token}`} })
      return data;
    },
    async upload({commit}, {where, formData}) {
      let { data } = await axios.post(`${config.server}/api/${where}`, formData, { headers : { 'x-token' : `${sessionStorage.token}`} })
      return data;
    }
  },
})

<template>
  <v-container fluid grid-list-md >
    <v-row row>
      <v-col cols="12">
        <v-card >
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">{{title}}</h2>
            <v-spacer />
            <v-btn text color="accent" small @click="save(1)" :loading="loading">Salva</v-btn>
            <v-btn text color="accent" small @click="save(2)" :loading="loading">Salva e continua</v-btn>
            <v-btn text color="accent" small @click="save(3)" :loading="loading">Salva e aggiungi</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid grid-list-md >
              <v-form ref="langForm" @submit.prevent="addLanguage()" lazy-validation>
                <v-row>
                  <v-col cols="12"><h2 class="subheading">Lingue: {{languages.join(", ")}}</h2></v-col>
                  <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                    <v-text-field outlined dense label="Aggiungi lingua" v-model="newLang" :rules="[rules.numchars(2),rules.required]"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                    <v-btn color="accent" type="submit">Aggiungi lingua</v-btn>
                  </v-col>
                </v-row>
              </v-form>
              <v-divider class="mb-6" />
              <v-form ref="form" @submit.prevent="save(1)">
                <v-row>
                  <v-col cols="12" sm="6" md="4" lg="3" xl="2">
                    <v-text-field outlined dense  label="Ordinamento" v-model="editingItem.order" :step="1" :rules="[rules.integer, rules.required]" type="number" ></v-text-field>
                  </v-col>
                  <v-col cols="12"><h2 class="subheading">Titolo</h2></v-col>
                  <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="lang in languages" :key="`title_${lang}`">
                    <v-text-field outlined dense  :label="lang" v-model="editingItem.title[lang]" :rules="[rules.required]"></v-text-field>
                  </v-col>
                  <v-col cols="12"><h2 class="subheading">Contenuto</h2></v-col>
                  <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="lang in languages" :key="`content_${lang}`">
                    <v-textarea outlined dense  :label="lang" v-model="editingItem.content[lang]"></v-textarea>
                  </v-col>
                  <v-col cols="12"><h2 class="subheading">Immagine</h2></v-col>
                  <v-col cols="12" md="8" lg="9" v-if="file || editingItem.image">
                    <v-img :aspect-ratio="16/9" class="grey" :src="imageSrc" contain></v-img>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <v-file-input label="Seleziona immagine" outlined dense v-model="file"></v-file-input>
                  </v-col>
                </v-row>
              </v-form>

            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text color="accent" small @click="save(1)" :loading="loading">Salva</v-btn>
            <v-btn text color="accent" small @click="save(2)" :loading="loading">Salva e continua</v-btn>
            <v-btn text color="accent" small @click="save(3)" :loading="loading">Salva e aggiungi</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import rules from '../utils/rules';
import { mapActions } from 'vuex';
export default {
  data () {
    return {
      loading : false,
      newLang : '',
      languages : ['it', 'en'],
      rules,
      file : null,
      editingItem : {
        order : 0,
        title : {it : '', en : '',},
        content : {it : '', en : '',},
        image : '',
      },

    }
  },
  computed : {
    updating() {
      return !!this.editingItem._id
    },
    title() {
      return this.$route.params.id === 'new' ? 'Nuovo help' : 'Modifica help';
    },
    imageSrc() {
      if(this.file) {
        return URL.createObjectURL(this.file);
      } else {
        return this.editingItem.image;
      }
    }
  },
  methods : {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'get', 'update', 'insert','upload']),
    addLanguage() {
      if(!this.$refs.langForm.validate()) return;
      if(this.languages.includes(this.newLang)) return;
      this.languages.push(this.newLang);
    },
    async loadData() {
      this.loading = true;
      let id = this.$route.params.id;
      try {
        let reply = await this.get({collection : 'helps', id})
        this.loading = false;
        if(reply.data) {
          this.editingItem = reply.data;
          this.languages = ['it','en']
          for(let lng in this.editingItem.title) {
            if(!this.languages.includes(lng))
              this.languages.push(lng)
          }
          if(this.editingItem.content){
            for(let lng in this.editingItem.content) {
              if(!this.languages.includes(lng))
                this.languages.push(lng)
            }
          }

        }
      } catch (e) {
        this.loading = false;
        let message = e.message ? e.message :"c'è stato un errore, riprova più tardi";
        this.sendError({message});
        this.$router.go(-1);
        if(e.statusCode === 401) {
          this.logout();
        }
      }
    },
    async save( afterAction ) {
      if(!this.$refs.form.validate()) return;
      this.loading = true;
      let updating = this.updating;
      let saveFn = updating ? this.update : this.insert;
      this.editingItem.order = parseInt(this.editingItem.order);
      try {
        let reply = await saveFn({collection:'helps', data : this.editingItem});
        this.editingItem = reply.data;
        if(this.file) {
          const formData = new FormData();
          formData.append('file', this.file)
          reply = await this.upload({where:`helps/${this.editingItem._id}/image`, formData})
          this.editingItem = reply.data;
        }

        this.sendSuccess({ message : "Help salvato"});
        switch (afterAction) {
          case 1:
            //go back
            await this.$router.replace("/helps");
            break;
          case 2:
            //stay
            if (this.$route.params.id === "new") {
              await this.$router.replace("/helps/" + reply.data._id);
            } else {
              this.editingItem = reply.data;
            }
            break;
          case 3:
            // new
            if (this.$route.params.id === "new") {
              this.editingItem = {
                order : 0,
                title : {it : '', en : '',},
                content : {it : '', en : '',},
                image : '',
              };
            } else {
              await this.$router.replace("/helps/new");
            }
            break;
        }
      } catch (err) {
        let message = err.message ? err.message : "c'è stato un errore, riprova più tardi";
        this.sendError({ message });
        if(err.statusCode === 401) {
          await this.$router.replace("/");
          this.logout();
        }
      }
    }
  },
  mounted() {
    if(this.$route.params.id !== 'new') {
      this.loadData()
    }
  }
}
</script>

<template>
  <v-app>
    <v-navigation-drawer v-if="loggedIn" v-model="drawer" :width="240" class="gradient-bg" clipped fixed dark app>
      <v-list dense>
        <div v-for="page in pages" :key="page.link">
          <v-list-group v-if="page.items" :prepend-icon="page.icon" :value="isSubPageActive(page.items)" active-class="accent--text">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{page.title}}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="subpage in page.items" :key="subpage.link" :to="subpage.link" router class="menu-subitem pl-6" active-class="accent--text">
              <v-list-item-action>
                <v-icon>{{subpage.icon}}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ subpage.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :to="page.link" router active-class="accent--text">
            <v-list-item-action>
              <v-icon>{{page.icon}}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ page.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>


        <v-list-item @click="logout()">
          <v-list-item-action>
            <v-icon>mdi-exit-to-app</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Esci</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar fixed clipped-left app dark class="secondary">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>
        <v-img class="d-inline-flex" max-height="36" max-width="190" src="@/assets/logo_xxh_noborder.png" title="ARTCENTRICA"></v-img>
        Brera
      </v-toolbar-title>
      <v-spacer />
    </v-app-bar>

    <v-main>
      <router-view v-if="loggedIn"/>

      <v-dialog v-model="confirmDialog" persistent max-width="400" >
        <v-card >
          <v-card-title class="headline">{{confirmDialogTitle}}</v-card-title>
          <v-card-text>
            <p class="body-1" v-html="confirmDialogMessage"></p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click.native="confirmDialogCallback(false); confirmDialog = false">No</v-btn>
            <v-btn color="error" text @click.native="confirmDialogCallback(true); confirmDialog = false">Sì</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="loginDialog" persistent max-width="320">
        <v-card >
          <v-form @submit.prevent="onLoginClick">
            <v-card-title class="headline">Login</v-card-title>
            <v-card-text>
              <v-text-field v-model="email" label="Email" outlined dense type="email"></v-text-field>
              <v-text-field v-model="password" label="Password" outlined dense :error-messages="loginError" :error="loginError.length > 0"
                            :type="showPwd ? 'text' : 'password'" :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showPwd = !showPwd"></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="accent" text type="submit" :loading="loading">Accedi</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>

      <v-snackbar
          dark
          :timeout="5000"
          :color="snackbarColor"
          top
          multi-line
          v-model="snackbar">
        {{ dialogMessage }}
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>

import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: 'App',

  data: () => ({
    loading : false,
    drawer : true,
    showPwd : false,
    email : null,
    password : null,
    tokenInterval : -1,
    loginError : [],
    pages : [
      { title : "Scheda opera", icon : "mdi-image", link : "/artwork" },
      { title : "Scheda artista", icon : "mdi-brush", link : "/artist" },
      { title : "Ratings", icon : "mdi-star", link : "/ratings" },
      { title : "Users", icon : "mdi-account", link : "/users" },
      { title : "Help", icon : "mdi-help-circle", link : "/helps" },
      { title : "Annotazioni", icon : "mdi-comment-plus", link : "/annotations" },
      { title : "Parolacce", icon : "mdi-book-alphabet", link : "/profanities" },
      { title : "Falsi positivi", icon : "mdi-alpha-p-box", link : "/fp" },
      { title : "Falsi negativi", icon : "mdi-alpha-n-box", link : "/fn" },
      { title : "Localizzazioni", icon : "mdi-translate", link : "/localizations" },
      { title : "Lingue", icon : "mdi-web", link : "/languages" },
    ]
  }),
  computed : {
    ...mapState(['confirmDialogTitle','confirmDialogMessage','confirmDialogCallback', 'dialogMessage', "helps"]),
    ...mapGetters(['loggedIn']),
    loginDialog() {
      return !this.loggedIn;
    },
    successDialog : {
      get() {
        return this.$store.state.successDialog;
      },
      set(newVal) {
        this.$store.state.successDialog = newVal;
      }
    },
    errorDialog : {
      get() {
        return this.$store.state.errorDialog;
      },
      set(newVal) {
        this.$store.state.errorDialog = newVal;
      }
    },
    confirmDialog : {
      get() {
        return this.$store.state.confirmDialog;
      },
      set(newVal) {
        this.$store.state.confirmDialog = newVal;
      }
    },
    snackbar : {
      get() {
        return this.successDialog ||  this.errorDialog;
      },
      set(newVal) {
        this.successDialog = newVal;
        this.errorDialog = newVal;
      }
    },
    snackbarColor() {
      if(this.successDialog) return 'success';
      if(this.errorDialog) return 'error';
      return undefined;
    },
  },
  methods: {
    ...mapActions(['login','heartbeat','loadAll','logout']),
    async onLoginClick() {
      this.loading = true;
      this.loginError = [];
      try {
        await this.login({email:this.email, password:this.password});
        this.loading = false;
        this.heartbeat();
      } catch (e) {
        this.loginError = e.message  ? [e.message] : ["c'è stato un errore riprova più tardi"];
        this.loading = false;
      }
    },
  },
  mounted() {
    if(sessionStorage.token) {
      this.heartbeat()
    }
  }
};
</script>

<template>
  <v-container fluid grid-list-md >
    <v-row row>
      <v-col cols="12">
        <v-card >
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">{{title}}</h2>
            <v-spacer />
            <v-btn text color="accent" small @click="save(1)" :loading="loading">Salva</v-btn>
            <v-btn text color="accent" small @click="save(2)" :loading="loading">Salva e continua</v-btn>
            <v-btn text color="accent" small @click="save(3)" :loading="loading">Salva e aggiungi</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid grid-list-md >
              <v-row>

                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field label="Email*" outlined dense v-model="editingItem.email"
                                :error="mailError" :error-messages="mailErrorMessages"></v-text-field>
                </v-col>

                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field :label="'Password' + (updating ? '' : '*')" outlined dense v-model="editingItem.password" counter hint="min 8 caratteri"
                                :error="noPasswordError || malformedPasswordError" :error-messages="requiredValidPasswordMessages"
                                :type="showPwd ? 'text' : 'password'" :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="showPwd = !showPwd" autocomplete="new-password"></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4" xl="3">
                  <v-text-field :label="'Conferma password' + (updating ? '' : '*')" outlined dense v-model="editingItem.confirm_password" hint="uguale alla password"
                                :error="confirmPasswordError" :error-messages="confirmPasswordErrorMessages" counter
                                :type="showConfPwd ? 'text' : 'password'" :append-icon="showConfPwd ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="showConfPwd = !showConfPwd" autocomplete="new-password"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text color="accent" small @click="save(1)" :loading="loading">Salva</v-btn>
            <v-btn text color="accent" small @click="save(2)" :loading="loading">Salva e continua</v-btn>
            <v-btn text color="accent" small @click="save(3)" :loading="loading">Salva e aggiungi</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import utils from '../utils';
import { mapGetters, mapActions } from 'vuex';
import config from "../config";
import moment from 'moment'
export default {
  data () {
    return {
      loading : false,
      showPwd : false,
      showConfPwd : false,
      editingItem : {
        email : '',
        password : null,
        confirm_password : '',
      },
      noPasswordError : false,
      malformedPasswordError : false,
      confirmPasswordError : false,
      mailError : false,
    }
  },
  computed : {
    updating() {
      return !!this.editingItem._id
    },
    title() {
      return this.$route.params.id === 'new' ? 'Nuovo utente' : 'Modifica utente';
    },
    mailErrorMessages() {
      if(this.mailError) return ['inserisci una mail valida'];
      return []
    },
    requiredValidPasswordMessages() {
      if(this.noPasswordError || this.malformedPasswordError) return ['la password deve avere almeno 8 caratteri'];
      return [];
    },
    confirmPasswordErrorMessages() {
      if(this.confirmPasswordError) return ["password e conferma password devono essere uguali"];
      return [];
    }
  },
  methods : {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'get', 'update', 'insert']),
    loadData() {
      this.loading = true;
      let id = this.$route.params.id;
      this.get({collection : 'users', id}).then((reply)=>{
        this.loading = false;
        if(reply.data) {
          reply.data.password = ''
          this.editingItem = reply.data;
        }
      }).catch((err) => {
        this.loading = false;
        let message = err.message ? err.message :"c'è stato un errore, riprova più tardi";
        this.sendError({message});
        this.$router.go(-1);
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    },
    clearErrors() {
      this.noPasswordError = false;
      this.malformedPasswordError = false;
      this.confirmPasswordError = false;
      this.mailError = false;
    },
    save( afterAction ) {
      this.clearErrors();
      let updating = this.updating;

      if(!this.editingItem.email) {
        this.mailError = true;
        return;
      }

      if(this.editingItem.password && this.editingItem.password.length === 0 && !updating) {
        this.noPasswordError = true;
        return;
      }

      if(this.editingItem.password && this.editingItem.password.length < 8 && !updating) {
        this.malformedPasswordError = true;
        return;
      }

      if(this.editingItem.password && this.editingItem.password.length && this.editingItem.password !== this.editingItem.confirm_password) {
        this.confirmPasswordError = true;
        return;
      }

      let saveFn = updating ? this.update : this.insert;
      saveFn({collection:'users', data : this.editingItem}).then((reply)=>{
        if(reply.data) reply.data.password = '';
        this.sendSuccess({ message : "User saved"});
        switch (afterAction) {
          case 1:
            //go back
            this.$router.replace("/users");
            break;
          case 2:
            //stay
            if (this.$route.params.id === "new") {
              this.$router.replace("/users/" + reply.data._id);
            } else {
              this.editingItem = reply.data;
            }
            break;
          case 3:
            // new
            if (this.$route.params.id === "new") {
              this.editingItem = {
                email : '',
                password : null,
                confirm_password : '',
              };
            } else {
              this.$router.replace("/users/new");
            }
            break;
        }
      }).catch((err)=>{
        let message = err.message ? err.message : "c'è stato un errore, riprova più tardi";
        this.sendError({ message });
        if(err.statusCode === 401) {
          this.$router.replace("/");
          this.logout();
        }
      })

    }
  },
  mounted() {
    if(this.$route.params.id !== 'new') {
      this.loadData()
    }
  }
}
</script>

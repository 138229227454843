import _ from 'lodash'
export default {
    maxchars: (num) => {
        return value => {
            if(value && value.length > num) {
                return `massimo ${num} caratteri`;
            }
            return true;
        }
    },
    numchars: (num) => {
        return value => {
            if(value && value.length !== num) {
                return `deve avere ${num} caratteri`;
            }
            return true;
        }
    },
    notEmpty:(v)=> {
        if(v && v.length) return true;
        return 'campo obbligatorio'
    },
    required:(v)=> !!v || 'campo richiesto',
    integer:(v)=> {
        return _.isNumber(parseInt(v)) ? true : 'deve essere un numero intero'
    },
}
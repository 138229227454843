import config from '../config'
import _ from 'lodash'
import moment from 'moment'
const getChildrenOf = (item, map) => {
    if(map[item._id]) {
        let children = map[item._id];
        for(let i = 0; i < children.length; i++) {
            let item = children[i];
            children[i] = getChildrenOf(item, map);
        }
        item.children = children;
    }
    return item;
};

const index = {
    RANDOM_STRING_LETTERS : "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
    RANDOM_STRING_LETTERS_EXTENDED : "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!£$%&=?^.,;:-_*+",
    UNMISTAKABLE_CHARS : "23456789ABCDEFGHJKLMNPQRSTWXYZabcdefghijkmnopqrstuvwxyz",
    UNMISTAKABLE_CHARS_UPPER : "23456789ABCDEFGHJKLMNPQRSTWXYZ",
    randomString:(characters, length) => {
        let result = '';
        const charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    },
    isValidPassword( password ) {
        return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.{8,})/.test(password)
    },
    isValidPhoneNumber : (num) => {
        let re = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
        return re.test(num);
    },
    isValidEmail : (mail) => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(mail).toLowerCase());
    },
    isValidColor : ( color ) => {
        let re = /^#[0-9A-F]{6}$/i;
        return re.test(color);
    },
    copy : ( obj ) => {
        return JSON.parse(JSON.stringify(obj));
    },
    userToProfile( user ) {
        return {
            _id: user._id,
            username: user.username,
            emails: user.emails,
            metaroles: user.metaroles,
            roles: user.roles,
            translateLang: user.translateLang,
            name: user.name,
            surname: user.surname,
            collections: user.collections
        }
    },
    buildRolesMap( metaroles ) {
        let map = {};
        for(let i = 0; i < metaroles.length; i++) {
            let name = metaroles[i];

            let data = config.getMetarole(name);
            if(!data) continue;

            for(let j = 0; j < data.roles.length; j++) {
                let role = data.roles[j];
                if(!role.group) {
                    if(!map.__global_roles__) {
                        map.__global_roles__ = [];
                    }
                    if(!_.includes( map.__global_roles__, role.role)) {
                        map.__global_roles__.push(role.role);
                    }
                } else {
                    if(!map[role.group]) {
                        map[role.group] = [];
                    }
                    if(!_.includes(map[role.group], role.role)) {
                        map[role.group].push(role.role)
                    }
                }
            }
        }
        return map;
    },
    formatForInput(date) {
        if(!date) return '-';
        return moment(date).format('YYYY-MM-DD ')
    },
    formatDate(date) {
        if(!date) return '-';
        return moment(date).format('L')
    },
    formatDateTime(date) {
        if(!date) return '-';
        return moment(date).format('L LT')
    },
    parseDate(date) {
        if(!date) return '-';
        //'DD/MM/YYYY'
        return moment(date,'DD/MM/YYYY').toDate()
    },
    toFloat(val, defaultVal) {
        if(_.isNil(val)) return defaultVal;
        if(_.isNumber(val)) return val;
        if(_.isString(val)) {
            let num = parseFloat(val.replace(",","."));
            if(!_.isNaN(num)) {
                return num;
            }
        }
        return defaultVal;
    },
    toInt(val, defaultVal) {
        if(_.isNil(val)) return defaultVal;
        if(_.isNumber(val)) return val;
        if(_.isString(val)) {
            let num = parseInt(val.replace(",","."), 10);
            if(!_.isNaN(num)) {
                return num;
            }
        }
        return defaultVal;
    },
    stringSearch(value, test) {
        return value.toLowerCase().indexOf(test.toLowerCase()) !== -1;
    },
    stringInChild(timeline, parentId, lng, test){
        for(let i = 0; i < timeline.length; i++) {
            let item = timeline[i];
            if(item.parent === parentId) {
                if(index.stringSearch(item.label[lng], test) || index.stringInChild(timeline, item._id, lng, test)) {
                    return true
                }
            }
        }
        return false;
    },
    timelineAsTree(eras) {
        let tree = [];
        let map = {};
        for(let i = 0; i < eras.length; i++) {
            let era = index.copy(eras[i]);
            if(!era.parent) {
                tree.push(era)
            } else {
                if(!map[era.parent]) map[era.parent] = [];
                map[era.parent].push(era)
            }
        }
        for(let i = 0; i < tree.length; i++) {
            let item = tree[i];
            tree[i] = getChildrenOf(item, map)
        }
        return tree
    },
    encodeUrl( url ) {
        let spl = url.split("?");
        if(spl.length > 1) {
            let params = spl[1].split("&")
            params = params.map((param)=>{
                let kv = param.split("=")
                if(kv.length !== 2) {
                    return param;
                }
                return `${kv[0]}=${encodeURIComponent(kv[1])}`
            })
            url = [spl[0], "?", params.join("&")].join("")
        }
        return url
    },
    getObjectInArray(items, byKey, value) {
        if(!items || !value) return null;
        let filter = items.filter(item => item[byKey] === value)
        return filter.length ? filter[0] : null;
    },
    copyToClipboard(str) {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    },
    getMouseCoordinates(e, target) {
        const rect = target.getBoundingClientRect();
        const x = e.clientX - Math.round(rect.left); //x position within the element.
        const y = e.clientY - Math.round(rect.top);  //y position within the element.
        return { x, y }
    },
};
export default index;
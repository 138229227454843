<template>
  <v-container fluid grid-list-md >
    <v-row row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">Lista lingue</h2>
            <v-spacer />
            <v-btn color="accent" text @click="$router.push('/languages/new')">Nuova</v-btn>
          </v-card-title>
          <v-card-text>
            <v-container fluid grid-list-md >
              <v-row>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-text-field hide-details outlined dense v-model="nameSearch" label="Nome" @input="applyFilter" clearable></v-text-field>
                </v-col>
              </v-row>
              <v-row>

                <v-col cols="12">
                  <v-data-table :headers="headers" :items="items" :options.sync="pagination" :server-items-length="count" :loading="tableLoading"
                                no-data-text="non ci sono record al momento" :footer-props="footerProps">

                    <template v-slot:item.code="{ item }">
                      <a :href="'./#/languages/'+item._id" ><b>{{ item.code }}</b></a>
                    </template>

                    <template v-slot:item.edit="{ item }">
                      <v-btn color="accent" dark text icon v-on:click="$router.push({ name : 'language form', params : { id : item._id }})">
                        <v-icon dark>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:item.remove="{ item }">
                      <v-btn color="error" dark text icon v-on:click="requestDelete(item._id)">
                        <v-icon dark>mdi-close</v-icon>
                      </v-btn>
                    </template>

                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import config from "../config";
export default {
  data () {
    return {
      refreshTimeout : -1,
      nameSearch : null,
      tableLoading : false,
      footerProps : {
        "items-per-page-text" : 'n° record per pagina',
        "items-per-page-options" : [20,50,100],
        "items-per-page" : 20
      },
      count : 0,
      items : [],
      headers : [
        { text : 'Codice', sortable: true, align: 'left', value : 'code' },
        { text : 'Nome', sortable: true, align: 'left', value : 'name' },
        { text : 'Modifica', sortable: false, align: 'center', value : 'edit', width : 50 },
        { text : 'Elimina', sortable: false, align: 'center', value : 'remove', width : 50 },
      ],
      pagination: {},
      query : {}
    }
  },
  watch: {
    pagination: {
      handler () {
        sessionStorage.lngPagination = JSON.stringify(this.pagination);
        clearTimeout(this.refreshTimeout);
        this.refreshTimeout = setTimeout(this.refresh, 250);
      },
      deep: true
    }
  },
  computed : {
  },
  methods : {
    ...mapActions(['sendSuccess', 'sendError', 'requestConfirm', 'logout', 'load', 'remove']),
    applyFilter() {
      this.query = {};
      this.query.name = this.nameSearch ? this.nameSearch : undefined;
      sessionStorage.lngQuery = JSON.stringify(this.query);
      this.pagination.page = 1;
      clearTimeout(this.refreshTimeout);
      this.refreshTimeout = setTimeout(this.refresh, 250);
    },
    requestDelete( id ) {
      this.requestConfirm({ title : 'Delete request', message : 'Are you sure to delete this item permanently?', callback: ( accepted )=>{
          if(accepted) {
            this.remove({ collection : 'languages', id }).then(()=>{
              this.refresh();
              this.sendSuccess({message:'Item deleted!'});
            }).catch((err)=>{
              let message = err.message ? err.message : 'there was an error while removing the item';
              this.sendError({ message });
              if(err.statusCode === 401) {
                this.logout();
              }
            })

          }
        }});
    },
    refresh() {
      this.tableLoading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      let sort = null;
      let order = null;
      if(sortBy.length) {
        sort = sortBy[0];
        order = 'ASC';
        if(sortDesc[0]) {
          order = 'DESC';
        }
      }

      let filter = {
        query : this.query,
        limit : itemsPerPage,
        page,
        sort,
        order
      };

      this.load({ collection : 'languages', filter}).then((reply)=>{
        this.tableLoading = false;
        if(reply.data) {
          this.count = reply.count;
          this.items = reply.data;
        }
      }).catch((err)=>{
        this.tableLoading = false;
        this.sendError({message:"there was an error while loading data"});
        if(config.debug) console.error("Errore al caricamento delle lingue: " + err);
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    }
  },
  mounted() {
    if(sessionStorage.lngQuery) {
      this.query = JSON.parse(sessionStorage.lngQuery);
      this.nameSearch = this.query.name ? this.query.name : null;
    }
    if(sessionStorage.lngPagination) {
      this.pagination = JSON.parse(sessionStorage.lngPagination);
    }
    clearTimeout(this.refreshTimeout);
    this.refreshTimeout = setTimeout(this.refresh, 250);
  }
}
</script>

<template>
  <v-container fluid >
    <v-row>
      <v-col cols="12">
        <v-card >
          <v-card-title class="pb-0">
            <h2 class="display-1 ml-4">Localizzazioni</h2>
            <v-spacer />
          </v-card-title>
          <v-card-text>
            <v-container fluid grid-list-md >
              <v-row>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-text-field hide-details outlined dense v-model="keySearch" label="Cerca per chiave" @input="applyFilter" append-icon="mdi-magnify" clearable></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-text-field hide-details outlined dense v-model="valueSearch" label="Cerca per valore" @input="applyFilter" append-icon="mdi-magnify" clearable></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-select hide-details outlined dense v-model="lngSearch" label="Lingua di ricerca" :items="languageOptions" @input="applyFilter"></v-select>
                </v-col>
                <v-col cols="12"><v-divider /></v-col>
              </v-row>
              <v-row>

                <v-col cols="12">
                  <v-data-iterator :items="items" :options.sync="pagination" :server-items-length="count" :loading="tableLoading"
                                   no-data-text="non ci sono record al momento" :footer-props="footerProps" >
                    <template v-slot:default="props">
                      <v-row v-for="item in props.items" :key="item._id">
                        <v-col cols="12">Chiave: <h3>{{item.key}} <v-btn color="error" icon @click="requestDelete(item._id)"><v-icon>mdi-close</v-icon></v-btn></h3></v-col>
                        <v-col cols="12" md="6" lg="4" xl="3" v-for="lang in languages" :key="item._id + '_' + lang._id">
                          <v-textarea hide-details :label="lang.code + ' ('+lang.name+')'" dense v-model="item.values[lang.code]" clearable outlined rows="1" auto-grow></v-textarea>
                        </v-col>
                        <v-col cols="12" md="6" lg="4" xl="3">
                          <v-btn @click="updateLocalization(item)" color="accent">Salva</v-btn>
                        </v-col>
                        <v-col cols="12"><v-divider /></v-col>
                      </v-row>
                    </template>
                  </v-data-iterator>
                </v-col>

                <v-col cols="12"><v-divider /></v-col>

                <v-col cols="12">

                  <v-row>
                    <v-col cols="12"><h3>Inserisci nuova chiave</h3></v-col>
                    <v-col cols="12">
                      <v-text-field outlined dense label="Chiave" v-model="newItem.key" :error="keyError" :error-messages="keyErrorMessages"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" lg="4" xl="3" v-for="lang in languages" :key="'newitem_' + lang._id">
                      <v-textarea hide-details :label="lang.code + ' ('+lang.name+')'" v-model="newItem.values[lang.code]" clearable outlined dense rows="1" auto-grow></v-textarea>
                    </v-col>
                    <v-col cols="12" md="6" lg="4" xl="3">
                      <v-btn @click="insertLocalization()" color="accent">Inserisci</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import config from "@/config";

export default {
  name: "Localizations",
  data () {
    return {
      refreshTimeout : -1,
      keyError : false,
      keySearch : null,
      valueSearch : null,
      lngSearch : 'it',
      tableLoading : false,
      count : 0,
      items : [],
      footerProps : {
        "items-per-page-text" : 'n° record per pagina',
        "items-per-page-options" : [10,20,50],
        "items-per-page" : 10
      },
      pagination: {},
      query : {},
      newItem : {
        key : "",
        values : {},
      }
    }
  },
  watch: {
    pagination: {
      handler () {
        sessionStorage.locsPagination = JSON.stringify(this.pagination)
        clearTimeout(this.refreshTimeout);
        this.refreshTimeout = setTimeout(this.refresh, 250);
      },
      deep: true
    }
  },
  computed : {
    ...mapGetters(['languages']),
    keyErrorMessages() {
      return this.keyError ? ['campo richiesto'] : [];
    },
    languageOptions() {
      return this.languages.map(lang => {
        return { text : lang.lang, value: lang.code}
      })
    }
  },
  methods : {
    ...mapActions(['loadAll','load','remove','update','insert','sendSuccess','sendError','requestConfirm']),
    applyFilter() {
      this.query = {};
      this.query.key = this.keySearch ? this.keySearch : undefined;
      this.query.lng = this.lngSearch;
      this.query.q = this.valueSearch ? this.valueSearch : undefined;

      this.pagination.page = 1
      sessionStorage.locsQuery = JSON.stringify(this.query)
      clearTimeout(this.refreshTimeout);
      this.refreshTimeout = setTimeout(this.refresh, 250);
    },
    insertLocalization() {
      this.keyError = false;
      if(!this.newItem.key) {
        this.keyError = true;
        return;
      }
      this.insert({ collection : 'localizations', data : this.newItem}).then((reply)=>{
        this.sendSuccess({message: "Key saved!"});
        this.refresh()
        this.newItem = {
          key : "",
          values : {},
        };
      }).catch((err)=>{
        let message = err.message ? err.message :"there was an error while saving data";
        this.sendError({ message });
        if(config.debug) console.error("errore all'inserimento della localizzazione",err);
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    },
    updateLocalization( data ) {

      this.update({ collection : 'localizations', data }).then((reply)=>{
        this.sendSuccess({message: "Key saved!"});
        this.refresh()
      }).catch((err)=>{
        let message = err.message ? err.message : "there was an error while saving data";
        this.sendError({ message });
        if(config.debug) console.error("errore all'aggiornamento della localizzazione",err);
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    },
    requestDelete( id ) {
      this.requestConfirm({ title : 'Delete request', message : 'Are you sure to delete this item permanently?', callback: ( accepted )=>{
          if(accepted) {
            this.remove({ collection : 'localizations', id }).then(()=>{
              this.refresh();
              this.sendSuccess({message:'Item deleted!'});
            }).catch((err)=>{
              let message = err.message ? err.message : 'there was an error while removing the item';
              this.sendError({ message });
              if(config.debug) console.error("Errore di cancellazione della localizzazione",err);
              if(err.statusCode === 401) {
                this.logout();
              }
            })

          }
        }});
    },
    refresh() {
      this.tableLoading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      let sort = null;
      let order = null;
      if(sortBy.length) {
        sort = sortBy[0];
        order = 'ASC';
        if(sortDesc[0]) {
          order = 'DESC';
        }
      }

      let filter = {
        query : this.query,
        limit : itemsPerPage,
        page,
        sort,
        order
      };

      this.load({ collection : 'localizations', filter}).then((reply)=>{
        this.tableLoading = false;
        if(reply.data) {
          this.count = reply.count;
          this.items = reply.data;
        }
      }).catch((err)=>{
        this.tableLoading = false;
        this.sendError({message:"there was an error while loading data"});
        if(config.debug) console.error("Errore al caricamento delle localizzazioni: " + err);
        if(err.statusCode === 401) {
          this.logout();
        }
      })
    }
  },
  async mounted() {
    await this.loadAll({ collection : 'languages' });
    if(sessionStorage.locsQuery) {
      this.query = JSON.parse(sessionStorage.locsQuery)
      this.keySearch = this.query.key ? this.query.key : ''
    }
    if(sessionStorage.locsPagination) {
      this.pagination = JSON.parse(sessionStorage.locsPagination);
    }
    clearTimeout(this.refreshTimeout);
    this.refreshTimeout = setTimeout(this.refresh, 250);
  }
}
</script>

<style scoped>

</style>
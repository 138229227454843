<template>
  <div id="xlv-wrap" >
    <div ref="viewer" id="viewer" :class="'xlviewer ' + className"></div>

    <div v-if="annotations && annotations.length" id="annotations" :key="'ann_'+seed" :class="tracking ? 'editing' : ''"
         @mousemove="mouseMove" @mouseup="mouseUp" @mouseleave="mouseUp" >
      <div v-for="(ann, index) in annotations" :class="['annotation', (ann.editing ? 'editing':'')]"
           :key="ann._id" :style="buildAnnotationStyle(ann)" v-if="ann.show" @mousedown="trackMouse($event, ann)">
      </div>
    </div>

  </div>

</template>

<script>
import utils from '../utils'
import XLviewer from '../xlviewer'

export default {
  name: "XlViewer",
  data() {
    return {
      model : this.value,
      viewer : null,
      toolbar : null,
      seed : 0,
      editingAnnotation : null,
      tracking : false,
      startingEvent : null,
      loop : null,
    }
  },
  props: {
    value : {
      type: String,
      required: true,
    },
    annotations : {
      type: Array,
      default: () => {
        return [];
      }
    },
    server : {
      type: String,
      required: true
    },
    quality : {
      type: Number,
      default: 70
    },
    className: {
      type: String,
      default : ''
    },
    forceHttps : {
      type :Boolean,
      default : false
    }
  },
  watch : {
    value() {
      this.model = this.value;
      console.log("change viewer model")
      this.load();
    },
  },
  computed : {
    serverUrl() {
      if(!this.server) return '';
      let url = this.server;
      if(this.forceHttps && url.indexOf('https://') === -1) {
        url = url.replace('http://', 'https://')
      }
      return url;
    }
  },
  methods: {

    trackMouse(e, ann) {
      e.stopImmediatePropagation();
      this.editingAnnotation = ann;
      this.tracking = true;
      this.startingEvent = e;
    },
    mouseMove(e) {

      if(!this.tracking) {
        return
      }

      const target = document.getElementById('annotations');
      const position = utils.getMouseCoordinates(e, target);
      let coords = this.viewer.sceneToLocal(new XLviewer.Point(position.x,position.y));
      this.editingAnnotation.x = coords.x;
      this.editingAnnotation.y = coords.y;
    },
    mouseUp(e) {
      if(!this.tracking) {
        return
      }
      console.log("up");
      this.editingAnnotation = null;
      this.tracking = false;
      this.startingEvent = null;
    },
    zoomIn() {
      this.viewer.zoomIn()
    },
    zoomOut() {
      this.viewer.zoomOut()
    },
    reset() {
      this.viewer.zoomReset()
    },
    getPoint( clientX, clientY ) {
      return this.viewer.sceneToLocal( new XLviewer.Point(clientX,clientY), true, false )
    },
    getLocalPoint( boundsX, boundsY ) {
      return this.viewer.localToScene( new XLviewer.Point(boundsX,boundsY), true )
    },
    getBounds( current ) {
      return this.viewer.getBounds( current )
    },
    zoomToBounds(x, y, w, h, immediately, applyConstraint) {
      this.viewer.zoomToBounds( new XLviewer.Rect(x,y,w,h), immediately, applyConstraint);
    },
    _build() {
      if(!this.model)  {
        return;
      }
      console.log("build viewer")
      this.viewer = new XLviewer({
        // debug: true,
        id: 'viewer',
        quality: this.quality,
        useNavigator : false,
        source : this.serverUrl,
        image : this.model
      });
      this.viewer.addListener('viewportChange', this.onViewportChange)
    },
    buildAnnotationStyle(ann) {
      let center = this.viewer.localToScene(new XLviewer.Point(ann.x,ann.y));

      return `top: ${center.y-5}px; left: ${center.x-5}px;`;
    },
    load() {
      if(this.viewer) {
        this.viewer.destroy();
      }
      this._build();
    },
    onInput(val) {
      this.$emit('input', this.model)
    },
    setAnnotations( items ) {
      this.annotations = items;
      this.seed++
    },
    forceRedraw() {
      this.seed++
    },
    onResize () {
      this.$emit('resize', {width:this.$refs.viewer.offsetWidth, height:this.$refs.viewer.offsetHeight})
    },
    onViewportChange() {
      //console.log(this.viewer.getBounds( true ))
      this.$emit('viewportChange')
    },
  },
  mounted() {
    this.ro = new ResizeObserver(this.onResize);
    this.ro.observe(this.$refs.viewer);
    if(!this.value) return;
    this.load();
  },
  beforeDestroy() {
    this.viewer && this.viewer.destroy();
    this.ro && this.ro.unobserve(this.$refs.viewer)
    this.ro = null;
  }
}
</script>

<style scoped>
#xlv-wrap{
  position: relative;
  background: black;
  width: 100%;
  height: 500px;
}

#viewer, #annotations {
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

#annotations {
  pointer-events:none;
}

#annotations .annotation {
  cursor: auto;
  position: absolute;
  pointer-events:none;
  background: rgba(255,255,255,.3);
  border: 2px solid rgba(255,255,255,.5);
  border-radius: 5px;
  width: 10px;
  height: 10px;
  box-sizing: border-box;
  z-index: 9999;
}

#annotations.editing{
  pointer-events:all;
}

#annotations .annotation.editing {
  pointer-events:all;
  cursor: pointer;
  background: rgba(255,0,0,.3);
  border: 2px solid rgba(255,0,0,.5);
}


</style>


import Vue from 'vue'
import VueRouter from 'vue-router'
import Users from '../pages/Users.vue'
import UserForm from '../pages/UserForm.vue'
import Profanities from "@/pages/Profanities";
import FalsePositives from "@/pages/FalsePositives";
import FalseNegatives from "@/pages/FalseNegatives";
import Helps from "@/pages/Helps";
import HelpForm from "@/pages/HelpForm";
import Annotations from "@/pages/Annotations";
import Artwork from "@/pages/Artwork";
import Artist from "@/pages/Artist";
import Languages from "@/pages/Languages";
import LanguageForm from "@/pages/LanguageForm";
import Localizations from "@/pages/Localizations";
import Votes from "@/pages/Votes";

Vue.use(VueRouter)

const routes = [
  {
    path: '/artwork',
    name: 'artwork',
    component: Artwork
  },
  {
    path: '/artist',
    name: 'artist',
    component: Artist
  },
  {
    path: '/annotations',
    name: 'annotations',
    component: Annotations
  },
  {
    path: '/helps',
    name: 'helps',
    component: Helps
  },
  {
    path: '/helps/:id',
    name: 'help form',
    component: HelpForm
  },
  {
    path: '/users',
    name: 'users',
    component: Users
  },
  {
    path: '/users/:id',
    name: 'user form',
    component: UserForm
  },
  {
    path: '/languages',
    name: 'languages',
    component: Languages
  },
  {
    path: '/languages/:id',
    name: 'language form',
    component: LanguageForm
  },
  {
    path: '/localizations',
    name: 'localizations',
    component: Localizations
  },
  {
    path: '/profanities',
    name: 'profanities',
    component: Profanities
  },
  {
    path: '/fp',
    name: 'fp',
    component: FalsePositives
  },
  {
    path: '/fn',
    name: 'fn',
    component: FalseNegatives
  },
  {
    path: '/ratings',
    name: 'ratings',
    component: Votes
  },
  {
    path: '*',
    redirect: {
      name: 'artwork',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
